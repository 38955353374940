import * as React from "react"
import SiteContainer from "../components/site-container";
import {Helmet} from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import analytics from "../images/vectors/undraw_Analytics_re_dkf8.svg"
import attribution from "../images/vectors/undraw_Business_analytics_64el.svg"
import audiences from "../images/vectors/undraw_having_fun_re_vj4h.svg"
import automation from '../images/vectors/undraw_functions_egi3.svg';

import PricingSection from "../components/PricingSection";
import Logout from "@mui/icons-material/Logout";
import PeopleIcon from "@mui/icons-material/People";
import SegmentIcon from "@mui/icons-material/Segment";
import AccountTree from "@mui/icons-material/AccountTree";

const IndexPage = () => {
    return (
        <SiteContainer>
            <Helmet>
                <title>Firstparty - Analytics & Attribution for Marketers & Developers</title>
                <meta name="description" content="Firstparty connects online conversions to offline data so you can optimize your campaigns and close the loop on attribution." />
                <link rel="icon" href="data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y=%22.9em%22 font-size=%2290%22>🎉</text></svg>" />
            </Helmet>

            <section className="py-8">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={10} className="text-center">
                            <h1 className="display-3 fw-bold">Make Every Marketing Dollar Count
                            </h1>
                            <p className="lead text-muted mb-6">
                                Connect online conversions to offline data to optimize your campaigns, increase conversion rates, and close the loop on attribution with <strong>your own first-party data</strong>.
                            </p>
                            <p className="mb-7 mb-md-9">
                                <a className="btn btn-primary shadow lift" href="https://app.firstpartyhq.com/authentication/signup">
                                    Get Started for Free<i className="fe fe-arrow-right ms-2" />
                                </a>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="py-8">
                <div className="container">
                    <div className="row">
                        <div className="col text-center text-gray">
                            <a href="/solutions/attribution/" className="text-decoration-none">
                                <div className="card card-border border-primary">
                                    <div className="card-body">
                                        <div className="icon icon-sm text-primary pb-3">
                                            <Logout />
                                        </div>
                                        <h3>Attribution</h3>
                                    </div>
                                </div>
                            </a>

                        </div>
                        <div className="col text-center text-gray">
                            <a href="/solutions/audiences/" className="text-decoration-none">
                                <div className="card card-border border-primary">
                                    <div className="card-body">
                                        <div className="icon icon-sm text-primary pb-3">
                                            <PeopleIcon />
                                        </div>
                                        <h3>Audiences</h3>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col text-center text-gray">
                            <a href="/solutions/analytics/" className="text-decoration-none">
                                <div className="card card-border border-primary">
                                    <div className="card-body">
                                        <div className="icon icon-sm text-primary pb-3">
                                            <SegmentIcon />
                                        </div>
                                        <h3>Analytics</h3>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col text-center text-gray">
                            <a href="/solutions/automation/" className="text-decoration-none">
                                <div className="card card-border border-primary">
                                    <div className="card-body">
                                        <div className="icon icon-sm text-primary pb-3">
                                            <AccountTree />
                                        </div>
                                        <h3>Automation</h3>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <div className="section bg-secondary pt-10 pb-1">
                <div className="container">
                    <div className="row justify-content-between align-items-center mb-10">
                        <div className="col text-center">
                            <img className="img-fluid w-75 w-md-100 mb-6 mb-md-0 pe-5" src={attribution} alt="..." />
                        </div>
                        <div className="col-12 col-md-6">
                            <h6 className="text-white text-uppercase">
                                Attribution
                            </h6>
                            <h2 className="text-white">
                                Optimize campaigns for the business objectives you actually care about
                            </h2>
                            <div className="lead text-light mb-5">
                                Know every channel, source, pageview, click, and event that resulted in a closed-won deal. Identify exactly which marketing efforts result in not just leads, but connected phone calls, pipeline generation, and revenue.
                            </div>
                            <a href="/solutions/attribution/" className="btn btn-primary">Explore Attribution <i className="fe fe-arrow-right ms-2" /></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="position-relative">
                <div className="shape shape-top shape-fluid-x text-secondary">
                    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0h2880v125h-720L720 250H0V0z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <div className="section bg-black pt-10 pb-3 pt-md-14">
                <div className="container">
                    <div className="row justify-content-between align-items-center mb-10">
                        <div className="col order-md-2 text-center">
                            <img className="img-fluid w-75 w-md-100 mb-6 mb-md-0" src={audiences} alt="..." />
                        </div>
                        <div className="col-12 col-md-7 order-md-1">
                            <h6 className="text-white text-uppercase">
                                Audiences
                            </h6>
                            <h2 className="text-white">
                                Create Audiences from any source, including offline data
                            </h2>
                            <div className="lead text-muted-80 mb-5">
                                Create Segments of Events and build Audiences of individuals from web activity combined with
                                data from other marketing tools, CRM, internal databases, and more.
                            </div>
                            <a href="/solutions/audiences/" className="btn btn-secondary">Explore Audiences <i className="fe fe-arrow-right ms-2" /></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="position-relative">
                <div className="shape shape-top shape-fluid-x shape-flip-x text-black">
                    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0h2880v125h-720L720 250H0V0z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <div className="section bg-secondary pt-10 pb-3 pt-md-12 pb-md-5">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col text-center">
                            <img className="img-fluid w-75 w-md-100 mb-6 mb-md-0" src={analytics} alt="..." />
                        </div>
                        <div className="col-12 col-md-7">
                            <h6 className="text-white text-uppercase">
                                Analytics
                            </h6>
                            <h2 className="text-white">
                                Own all of your marketing Analytics data
                            </h2>
                            <div className="lead text-light mb-5">
                                Firstparty uses first-party cookies to collect highly accurate website visitor activites,
                                and moves that data to a warehouse you control.
                            </div>
                            <a href="/solutions/analytics/" className="btn btn-white">Explore Analytics <i className="fe fe-arrow-right ms-2" /></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="position-relative">
                <div className="shape shape-top shape-fluid-x text-secondary">
                    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0h2880v125h-720L720 250H0V0z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <div className="section bg-dark pt-10 pt-md-13 pb-10">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col order-md-2 text-center">
                            <img className="img-fluid" src={automation} alt="..." />
                        </div>
                        <div className="col-12 col-md-6 order-md-1">
                            <h6 className="text-white text-uppercase">
                                Automation
                            </h6>
                            <h2 className="text-white">
                                Build powerful automations
                            </h2>
                            <div className="lead text-light mb-5">
                                React to collected Events in realtime with a simple rules engine that lets you update
                                customer information, push data to an external service, or even run custom Javascript
                                on any Event Firstparty collects.
                            </div>
                            <a href="/solutions/automation/" className="btn btn-light">Explore Automation <i className="fe fe-arrow-right ms-2" /></a>
                        </div>
                    </div>
                </div>
            </div>

            <section className="py-10 bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center mb-7">
                            <h3 className="h1">It's Your Data. <span className="text-primary fw-bold">Make it Work For You.</span></h3>
                        </div>
                        <div className="col-12 col-md-6 mb-10 mb-md-0">
                            <div className="card">
                                <div className="card-body">
                                    <h2 className="h3">Collect new first-party data</h2>
                                    <p className="text-gray-700">
                                        Marketing Analytics platforms typically rely on third-party cookies, and store data in walled gardens that make it difficult to analyze and activate.
                                    </p>
                                    <p className="text-gray-700 mb-0">
                                        Firstparty collects Events using first-party cookies, will transfer your data anywhere, and helps you activate collected data like never before.
                                    </p>
                                </div>
                                <div className="card-meta">
                                    <hr className="card-meta-divider" />
                                    <a className="fw-bold text-primary text-decoration-none" href="/solutions/analytics/">Collect Data with Firstparty Analytics<i className="fe fe-arrow-right ms-1"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="card">
                                <div className="card-body">
                                    <h2 className="h3">Activate existing first-party data</h2>
                                    <p className="text-gray-700">
                                        The modern data warehouse has information of customer activities from dozens of tools, many of which are disconnected from a browser session (offline) or happen days after a visitor clicks on an ad.
                                    </p>
                                    <p className="text-gray-700 mb-0">
                                        Firstparty helps you activate this data, no matter where it was collected.
                                    </p>
                                </div>
                                <div className="card-meta">
                                    <hr className="card-meta-divider" />
                                    <a className="fw-bold text-primary text-decoration-none" href="/solutions/attribution/">Activate Data with Firstparty Attribution<i className="fe fe-arrow-right ms-1"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="section bg-black py-10">
                <div className="container">
                    <div className="row align-items-center justify-content-center mb-8">
                        <div className="col-12 col-lg-8 text-center">
                            <h2 className="h1 text-white-70">Designed for Marketers <span className="text-white"> and Developers</span></h2>
                            <p className="lead text-white-70">
                                Firstparty is built API-first, and with the ability to write custom code and queries,
                                there's no limit to what your team can build to better serve your customers.
                            </p>
                            <a className="btn btn-white shadow lift mt-5" href="https://app.firstpartyhq.com/authentication/signup">
                                Get Started for Free<i className="fe fe-arrow-right ms-2" />
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6 pb-5 pb-md-10 img-skewed img-skewed-end">
                            <div className="card rounded-lg mb-6 mb-md-0 img-skewed-item screenshot" style={{background: "rgba(0,0,0,.7)"}}>
                                <div className="card-header border-white-10">
                                    <div className="d-flex">
                                        <span className="bg-danger rounded-circle"
                                              style={{width: "10px", height: "10px"}} />
                                        <span className="bg-warning rounded-circle ms-1"
                                              style={{width: "10px", height: "10px"}} />
                                        <span className="bg-success rounded-circle ms-1"
                                              style={{width: "10px", height: "10px"}} />
                                    </div>
                                </div>
                                <div className="card-body">
                                    <code className="highlight hljs"
                                          style={{minHeight: "183px"}}>
                                        exports.handler = (event, context, callback) => &#123;
                                        <br /><br />
                                        <div className="px-3">
                                            <span className="text-success">// Set a key/value on the Event</span>
                                            <br />
                                            event.setProperty('barfoo', 'foobar');
                                            <br /><br />
                                            <span className="text-success">// Make an HTTP request with node-fetch</span>
                                            <br />
                                            fetch('https://api.github.com/users/github')
                                            <br />
                                            <div className="px-3">
                                                .then(res => res.json())
                                                <br />
                                                .then(json => &#123;
                                                <br /><br />
                                                <div className="px-3">
                                                    <span className="text-success">// Set a key/value on the Event</span>
                                                    <br />
                                                    event.setProperty('payload', json);
                                                    <br /><br />
                                                    <span className="text-success">// Finish work and end this Action's execution</span>
                                                    <br />
                                                    callback();
                                                    <br />
                                                </div>
                                                &#125;);
                                            </div>
                                        </div>
                                        &#125;
                                    </code>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 my-auto">
                            <h2 className="h3 text-white-70">Run Javascript in Response to Any Event</h2>
                            <p className="text-white-70">
                                Javascript Actions allow you to write code that executes in response to any (or
                                every) Event you collect - even from the browser.
                            </p>
                            <a className="fw-bold text-white text-decoration-none" href="/solutions/automation/">Learn More About Automation<i className="fe fe-arrow-right ms-1"></i></a>
                            <a className="fw-bold text-white-70 text-decoration-none mx-5" href="/docs/actions/javascript/">Read the Docs<i className="fe fe-arrow-right ms-1"></i></a>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12 col-md-6 my-auto order-2 order-md-1">
                            <h2 className="h3 text-white-70">Turn a Warehouse Query into Events</h2>
                            <p className="text-white-70">
                                Firstparty will create an Event for every row of data your warehouse query returns,
                                which may be used to trigger custom Automations or send conversions to ad networks.
                            </p>
                            <a className="fw-bold text-white text-decoration-none" href="/docs/sources/overview/">Read the Docs<i className="fe fe-arrow-right ms-1"></i></a>
                        </div>

                        <div className="col-12 col-md-6 img-skewed img-skewed-start order-1 order-md-2">
                            <div className="card rounded-lg mb-6 mb-md-0 img-skewed-item screenshot" style={{background: "rgba(0,0,0,.7)"}}>
                                <div className="card-header border-white-10">
                                    <div className="d-flex">
                                        <span className="bg-danger rounded-circle"
                                              style={{width: "10px", height: "10px"}} />
                                        <span className="bg-warning rounded-circle ms-1"
                                              style={{width: "10px", height: "10px"}} />
                                        <span className="bg-success rounded-circle ms-1"
                                              style={{width: "10px", height: "10px"}} />
                                    </div>
                                </div>
                                <div className="card-body">
                                    <code className="highlight hljs"
                                          data-typed="{&quot;backSpeed&quot;:2, &quot;strings&quot;: [&quot;>&nbsp;$&nbsp;npm&nbsp;install<br/><span class=\&quot;text-success\&quot;>Everything&nbsp;is&nbsp;installed</span><br/><br/>>&nbsp;$&nbsp;npm start<br/><span class=\&quot;text-success\&quot;>scss&nbsp;watching</span><br/><span class=\&quot;text-success\&quot;>LiveReload&nbsp;started</span><br/><span class=\&quot;text-success\&quot;>Opening&nbsp;localhost:8080</span><br/><br/>>&nbsp;$&nbsp;that’s&nbsp;it<br/><span class=\&quot;text-success\&quot;>Yup,&nbsp;that’s&nbsp;it.</span>&quot;]}"
                                          style={{minHeight: "183px"}}>
                                        SELECT
                                        <div className="px-3">
                                            contacts.email, <br />contacts.facebook_click_id, <br />opportunities.value
                                        </div>
                                        <br />
                                        FROM
                                        <div className="px-3">contacts</div>
                                        <br />
                                        JOIN
                                        <div className="px-3">opportunities</div>
                                        ON
                                        <div className="px-3">
                                            opportunities.account_id = contacts.account_id
                                        </div>
                                    </code>
                                    <span className="typed-cursor" aria-hidden="true">|</span>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <PricingSection />

        </SiteContainer>
    )
}

export default IndexPage
